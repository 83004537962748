import { fetchSuggestions, isError } from '@/lib/helpers';
import { Suggestion } from '@/types/sermons';
import classNames from 'classnames';
import { KeyboardEvent, useEffect, useState } from 'react';
import { useNotifications } from '../providers/notification-provider';

interface SearchInputProps {
  term: string;
  setTerm: React.Dispatch<string>;
  suggestionTerm: string;
  setSuggestionTerm: React.Dispatch<React.SetStateAction<string>>;
  suggestions: Suggestion[];
  setSuggestions: React.Dispatch<React.SetStateAction<Suggestion[]>>;
  selectedSuggestion: Suggestion | null;
  handleSuggestionClick: (suggestion: Suggestion) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  searchSubType: string;
  isMySermons: boolean;
}

export default function SearchInput({
  term,
  setTerm,
  suggestionTerm,
  setSuggestionTerm,
  suggestions,
  setSuggestions,
  selectedSuggestion,
  handleSuggestionClick,
  onKeyDown,
  searchSubType,
  isMySermons,
}: SearchInputProps) {
  const addNotification = useNotifications();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [triggerSuggestions, setTriggerSuggestions] = useState(false);

  const [cancelNext, setCancelNext] = useState(true);

  useEffect(() => {
    setTriggerSuggestions(false);

    const fetchAndSetSuggestions = async () => {
      if (cancelNext) {
        setCancelNext(false);
        return;
      }
      if (suggestionTerm.trim() === '') {
        setSuggestions([]);
        return;
      }
      const fetchedSuggestions = await fetchSuggestions(suggestionTerm);
      if (isError(fetchedSuggestions)) {
        addNotification({
          type: 'error',
          message: fetchedSuggestions.message,
        });
        return;
      }
      setSuggestions(fetchedSuggestions);
    };

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      fetchAndSetSuggestions();
    }, 300);

    setTimeoutId(newTimeoutId);

    return () => {
      if (newTimeoutId) {
        clearTimeout(newTimeoutId);
      }
    };
  }, [suggestionTerm, setSuggestions, addNotification, triggerSuggestions]);

  return (
    <div className='relative'>
      <input
        type='text'
        className={classNames(
          'h-8 w-full rounded border border-neutral-300 px-2 py-1',
          {
            '!border-red-700': isMySermons,
          },
        )}
        value={suggestionTerm || term}
        onChange={(e) => {
          if (suggestionTerm) {
            setSuggestionTerm(e.target.value);
          }
          setTerm(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setSuggestions([]);
          } else if (e.key !== 'Enter') {
            setTriggerSuggestions(true);
          }
          onKeyDown(e);
        }}
        onClick={() => setTriggerSuggestions(true)}
        onFocus={() => setTriggerSuggestions(true)}
        placeholder={
          ['N/A', 'Show All'].includes(searchSubType) ? 'N/A' : 'Search...'
        }
        disabled={['N/A', 'Show All'].includes(searchSubType)}
      />
      {suggestions.length > 0 && (
        <ul className='absolute z-50 mt-1 max-h-60 w-full overflow-y-auto rounded border border-neutral-300 bg-white'>
          {suggestions.map((suggestion) => (
            <li
              key={suggestion.term}
              className={classNames(
                'cursor-pointer px-2 py-1 hover:bg-gray-100',
                {
                  'bg-gray-100': selectedSuggestion?.term === suggestion.term,
                },
              )}
              onClick={() => {
                setCancelNext(true);
                handleSuggestionClick(suggestion);
              }}
            >
              {suggestion.term}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
