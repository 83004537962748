'use client';

import { useSearchParams } from 'next/navigation';
import { Suspense, useEffect } from 'react';
import { useAppState } from '../providers/state-provider';

export default function SearchParamsTracker() {
  return (
    <Suspense>
      <ActualTracker />
    </Suspense>
  );
}

function ActualTracker() {
  const { dispatch } = useAppState();

  const searchParams = useSearchParams();

  useEffect(() => {
    dispatch({ type: 'searchParamsReady', payload: true });
    dispatch({ type: 'searchParams', payload: searchParams });
  }, [searchParams]);

  return null;
}
