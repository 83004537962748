import { SEARCH_SUB_TYPE } from '@/types/sermons';
import classNames from 'classnames';
import Link from 'next/link';
import { BsCaretDownFill } from 'react-icons/bs';
import { Tooltip } from 'react-tooltip';
import BaseButton from '../atoms/base-button';

interface SearchSubTypeDropdownProps {
  searchType: string;
  searchSubType: string;
  setSearchSubType: React.Dispatch<SEARCH_SUB_TYPE>;
  searchSubTypes: SEARCH_SUB_TYPE[];
  searchSubTypeOpen: boolean;
  setSearchSubTypeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMySermons: boolean;
  weekCycle: string;
}

export default function SearchSubTypeDropdown({
  searchType,
  searchSubType,
  setSearchSubType,
  searchSubTypes,
  searchSubTypeOpen,
  setSearchSubTypeOpen,
  isMySermons,
  weekCycle,
}: SearchSubTypeDropdownProps) {
  return (
    <div className='relative'>
      <BaseButton
        type='button'
        buttonType={isMySermons ? 'red' : 'gray'}
        className={classNames(
          'search-subtype-button inline-flex h-8 w-full items-center justify-center',
          {
            '!border-red-700': isMySermons,
          },
        )}
        onClick={() => setSearchSubTypeOpen(!searchSubTypeOpen)}
      >
        {searchSubType}
        {searchSubType === `Year ${weekCycle}` && (
          <span>
            {' '}
            <span className='icon-SermonsLogo relative top-px'></span>
          </span>
        )}{' '}
        <BsCaretDownFill className='ml-1 inline-block' />
        <Tooltip id='show-all-tooltip' place='right' className='z-50'>
          Browse all available {searchType}.
        </Tooltip>
      </BaseButton>
      {searchSubTypeOpen && (
        <ul className='absolute z-30 mt-1 max-h-60 w-full overflow-y-auto rounded border border-neutral-300 bg-white'>
          {searchSubTypes.map((type) => (
            <li
              key={type}
              className={classNames('cursor-pointer hover:bg-gray-100', {
                'px-2 py-1': type !== 'Show All',
              })}
              onClick={() => {
                setSearchSubType(type);
                setSearchSubTypeOpen(false);
              }}
            >
              {type === 'Show All' ? (
                (() => {
                  const url = new URL('https://null');
                  url.pathname = '/search/keyword';
                  url.searchParams.set('type', searchType);
                  url.searchParams.set('showAll', '');

                  return (
                    <Link
                      className='inline-block w-full px-2 py-1'
                      href={`${url.pathname}${url.search}`}
                      data-tooltip-id='show-all-tooltip'
                    >
                      Show All
                    </Link>
                  );
                })()
              ) : (
                <>
                  {type}
                  {type === `Year ${weekCycle}` && (
                    <span>
                      {' '}
                      <span className='icon-SermonsLogo relative top-px'></span>
                    </span>
                  )}
                </>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
